import { Injectable } from '@angular/core';
import { RouteDataProvider } from './RouteDataProvider.provider';
import { AreaDataProvider } from '../Providers/AreaDataProvider';
import { EventType, EventData } from '../Models/sharedModel';
import { UserProvider } from '../Providers/UserProvider';
import { DashboardProvider } from '../Providers/DashboardProvider';
import { WeekEntriesProvider } from '../Providers/WeekEntriesProvider';

@Injectable()

export class UserDataProvider extends AreaDataProvider {

    constructor(public routerDataProvider: RouteDataProvider,
        public userProviders: UserProvider,
        public dashBoardProviders: DashboardProvider,
        public weekEntriesProviders: WeekEntriesProvider) {
        super('Entries', routerDataProvider)
    }

    areaChanged(newArea: string, firstCall: boolean) {
        if (firstCall) {
            return;
        }
    }

    subAreaChanged(newSubArea: string, firstCall: boolean) {
        if (firstCall || newSubArea == this.currentAreaName) {
            return;
        }
        this.eventHandler({ type: EventType.subAreaChanged });
    }

    queryParamChanged(newQueryParams: any, firstCall: boolean) {
        if (firstCall) {
            return;
        }
        if (newQueryParams.id !== undefined) {
            this.eventHandler({ type: EventType.queryParamChanged });
        } else {
            this.eventHandler({ type: EventType.areaChanged });
        }
    }

    urlMaker(data: any): string {
        return "";
    }

    eventHandler(eventData: EventData) {
        switch (eventData.type) {
            case EventType.queryParamChanged:
                console.log('Query Param Changed', this.currentQueryParam);
                break;
            case EventType.areaChanged:
                console.log('Query Param Changed', this.currentAreaName);
            case EventType.subAreaChanged:
                console.log('Sub Area Changed', this.currentSubAreaName);
                switch (this.currentSubAreaName) {
                    case "entries-data":
                        console.log("i am inside entries");
                        this.weekEntriesProviders.getWeekEntries();
                        this.weekEntriesProviders.getEntriesCategory();
                        this.weekEntriesProviders.getWinnersEntries();
                        this.weekEntriesProviders.getEntiesWeeks();
                        this.weekEntriesProviders.getEntiesCity();
                        this.weekEntriesProviders.getEntiesPosition();
                        this.dashBoardProviders.getCampaignRewards();
                        this.dashBoardProviders.getDashBoardCounts();
                        this.weekEntriesProviders.getRatingOfEntries();
                        this.weekEntriesProviders.getRatingParameter();
                        this.dashBoardProviders.getEntiresBasedTask();
                        console.log(this.weekEntriesProviders);
                        break;
                }
        }
    }

}
