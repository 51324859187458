export class LoginForm{
  username : string;
  password: any;
}

export class UserModel {
  isModerator: boolean = true;
  isAuditor: boolean = false;
  Token: string;
  campaignId : number;
}

export class Users {
  user_id : number;
  username : string;
}

export class StateModel {
  id : number;
  state_code : number;
  state_name : string;
  region_id : number;
}

export class RegionModel {
  id: number;
  region_name: string;
  createdon: string;
  itemName: string;
  checked: boolean;
}

export class NotificationModel {
  id : number;
  user_id : number;
  notification : string;
  status : number;
}

export class FCMTokenModel {
  user_id : number;
  fcm_token : any;
}
