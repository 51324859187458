import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public apiHostUrl;

  constructor() {
    if (environment.production == true) {
      //this.apiHostUrl = 'https://api1.bud4trade.bigcityexperiences.com/v1';
      this.apiHostUrl = 'https://api1.staging4.bigcityvoucher.co.in/v1';
      //this.apiHostUrl = 'http://localhost/kingservice/api/web/v1';
    } else {
      //this.apiHostUrl = 'https://api1.bud4trade.bigcityexperiences.com/v1';
      this.apiHostUrl = 'https://api1.staging4.bigcityvoucher.co.in/v1';
      //this.apiHostUrl = 'http://localhost/kingservice/api/web/v1';
    }
  }
}
