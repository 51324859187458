import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { RouteDataProvider } from 'src/app/Shared/RouteDataProvider.provider';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ICompany } from 'src/app/Models/rewards';
import { Parameters, RatingParameter } from 'src/app/Models/WeekEntries';
import { WeekEntriesProvider } from 'src/app/Providers/WeekEntriesProvider';
import { MessagingService } from 'src/app/Shared/messaging.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { interval, Observable } from 'rxjs';
import { UserService } from 'src/app/Service/user.service';
import { NotificationModel } from 'src/app/Models/login-form';
import { UserProvider } from 'src/app/Providers/UserProvider';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit {
  
  message;
  currentRouter: string;
  modalRef: BsModalRef;
  score: number;
  itemScores: ICompany[];
  parameters: RatingParameter[];
  config = {
    animated: true,
    backdrop: false
  };
  ratingValidationMessage: string;
  userName: string;
  notification: NotificationModel[];
  userId: string;
//   Interval = setInterval(() => {
//     this.updateNotificationsCount();
//  }, 30000);


  constructor(public routerDataProviders: RouteDataProvider, public route: Router,
    private toastr: ToastrService,
    private modalService: BsModalService,
    public weekEntriesData: WeekEntriesProvider,
    private messagingService: MessagingService,
    private angularFireMessaging: AngularFireMessaging,
    public usersService: UserService,
    private toaster: ToastrService,
    private userProvider : UserProvider) {
    this.routerDataProviders.area.subscribe(
      m => {
        this.currentRouter = m;
        console.log(this.currentRouter);
        console.log('here in the layout');
      }
    );

    this.userProvider.loginName.subscribe(
      c=> {
        this.userName = c;
      }
    )    
  }

  

  ngOnInit() {
    this.notification = [];
    this.userName = localStorage.getItem('userName');
    const userId = localStorage.getItem('userId');
    // this.messagingService.requestPermission(userId)
    // this.messagingService.receiveMessage();
    // this.messagingService.recevieMessageInApp();
    this.message = this.messagingService.currentMessage;
    //this.listenNotification();
  }

  ngAfterViewInit() {
    //this.updateNotificationsCount();
  }

  removeNotification(item) {
    this.usersService.RemoveNotification(item).subscribe(
      (data) => {
        this.notification = data;
      }
    )
  }

  updateNotificationsCount() {
    this.userId = localStorage.getItem('userId');
    if(this.userId != null) {
      this.usersService.getAllNotification(this.userId).subscribe(
        (data) => {
          this.notification = data;
          if(this.notification.length > 0) {
            this.notification.forEach(
              p => {
                this.toaster.info(p.notification, 'Notification');
              }
            )
          }
        }
      )
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  

  ngDocheck() {
    this.parameters = this.weekEntriesData.ratingParameters;
    console.log(this.parameters);
  }

  listenNotification() {
    this.messagingService.listenToNotifications().subscribe(msg => {
      // msg.content = JSON.parse(msg.data.content);
      console.log(msg);
    });
  }


  logout() {
    localStorage.clear();
    localStorage.removeItem('access-token');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    this.toastr.success("Logout", "Success");
    this.route.navigate(['/']);
  }

  ngOnDestroy() {
    // clearInterval(this.Interval)
    localStorage;
    this.userName;
  }




}
