import { Rewards, WeekBasedTask, DashboardCountData, City, Category, Position, Week } from "./rewards";
import { WeeksBasedEntriesData } from "./rewards";
import { WeekEnttries, RatingModel, RatingParameter } from './WeekEntries';

export const REWARDS: Rewards[] = [
  { 'id': 1, 'prod_name': 'Amazon Echo Dot', 'status': 1 },
  // { 'id': 2, 'name': 'Reward 2', 'status': 1 },
  // { 'id': 3, 'name': 'Reward 3', 'status': 1 },
  // { 'id': 4, 'name': 'Reward 4', 'status': 1 }
]

export const DASHBOARDCOUNT: DashboardCountData[] = [
  { 'user_id': 1, 'total_entries': 1010, 'shortlisted_entries': 239, 'confirmed_entries': 989, 'rejected_entries': 89, 'onhold_entries': 899, 'unopened_entries': 56 },
  { 'user_id': 12, 'total_entries': 1010, 'shortlisted_entries': 239, 'confirmed_entries': 989, 'rejected_entries': 89, 'onhold_entries': 899, 'unopened_entries': 56 },
  { 'user_id': 12, 'total_entries': 1010, 'shortlisted_entries': 239, 'confirmed_entries': 989, 'rejected_entries': 89, 'onhold_entries': 899, 'unopened_entries': 56 }
]


export const REWARDENTRIES: WeeksBasedEntriesData[] = [

  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 1,
    'user_id': 1
  },
  // {
  //   'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
  //   'total_rewards': '100',
  //   'confirmed_winners': '200',
  //   'pending_winners': '300',
  //   'overdue_rewards': '200',
  //   'reward_id': 1,
  //   'user_id': 1
  // },
  // {
  //   'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
  //   'total_rewards': '100',
  //   'confirmed_winners': '200',
  //   'pending_winners': '300',
  //   'overdue_rewards': '200',
  //   'reward_id': 1,
  //   'user_id': 1
  // },
  // {
  //   'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
  //   'total_rewards': '100',
  //   'confirmed_winners': '200',
  //   'pending_winners': '300',
  //   'overdue_rewards': '200',
  //   'reward_id': 1,
  //   'user_id': 1
  // },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 2,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 2,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 2,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 3,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 3,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 3,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 3,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 3,
    'user_id': 2
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 4,
    'user_id': 1
  },
]


export const WEEKTASK: WeekBasedTask[] = []

// export const WEEKENTRIES: WeekEnttries[] = [
//   {
//     'id': 1, 'status': 0, 'isShortlisted': undefined, 'isRejected': undefined, 'isConfirmed': undefined, 'data_path': '../../../assets/images/gallery1.jpg', 'submited_date': '25-09-2019', 'customer_name': 'Suresh N',
//     'ratings':
//       [
//         { 'id': 1, 'rating': 0, 'name': 'Creativity' },
//         { 'id': 2, 'rating': 0, 'name': 'Sustainability' },
//         { 'id': 3, 'rating': 0, 'name': 'Usage of Colours' },
//         { 'id': 4, 'rating': 0, 'name': 'Photography' },
//         { 'id': 5, 'rating': 0, 'name': 'Nature Photography' },
//         { 'id': 6, 'rating': 0, 'name': 'Nature Photography' },
//         { 'id': 7, 'rating': 0, 'name': 'Nature Photography' }
//       ],
//     'comments': [
//       { 'id': 1, 'comment': 'Comment 1', 'commented_date': '25-10-2019 12:09:34', 'moderator_name': 'Moderator 1', 'entry_id': 1 },
//       { 'id': 2, 'comment': 'Comment 2', 'commented_date': '24-10-2019 12:09:34', 'moderator_name': 'Moderator 1', 'entry_id': 1 },
//       { 'id': 3, 'comment': 'Comment 3', 'commented_date': '23-10-2019 12:09:34', 'moderator_name': 'Moderator 1', 'entry_id': 1 },
//     ]
//   },
//   {
//     'id': 2, 'status': 0, 'isShortlisted': undefined, 'isRejected': undefined, 'isConfirmed': undefined, 'data_path': '../../../assets/images/gallery1.jpg', 'submited_date': '25-09-2019', 'customer_name': 'Suresh N',
//     'ratings': [
//       { 'id': 1, 'rating': 1, 'name': 'Creativity' },
//       { 'id': 2, 'rating': 3, 'name': 'Sustainability' },
//       { 'id': 3, 'rating': 1, 'name': 'Usage of Colours' },
//       { 'id': 4, 'rating': 5, 'name': 'Photography' },
//       { 'id': 5, 'rating': 6, 'name': 'Nature Photography' },
//       { 'id': 6, 'rating': 7, 'name': 'Nature Photography' },
//       { 'id': 7, 'rating': 8, 'name': 'Nature Photography' }
//     ],
//     'comments': []
//   },
//   {
//     'id': 3, 'status': 0, 'isShortlisted': undefined, 'isRejected': undefined, 'isConfirmed': undefined, 'data_path': '../../../assets/images/gallery1.jpg', 'submited_date': '25-09-2019', 'customer_name': 'Suresh N',
//     'ratings': [],
//     'comments': [
//       { 'id': 4, 'comment': 'Comment 1', 'commented_date': '25-10-2019 12:09:34', 'moderator_name': 'Moderator 1', 'entry_id': 2 },
//       { 'id': 5, 'comment': 'Comment 2', 'commented_date': '24-10-2019 12:09:34', 'moderator_name': 'Moderator 1', 'entry_id': 2 },
//       { 'id': 6, 'comment': 'Comment 3', 'commented_date': '23-10-2019 12:09:34', 'moderator_name': 'Moderator 1', 'entry_id': 2 },
//     ]
//   },

// ]

export const CITY: City[] = [
  { 'id': 1, 'name': 'Banglore' },
  { 'id': 2, 'name': 'Belgam' },
  { 'id': 3, 'name': 'Mysore' },
  { 'id': 4, 'name': 'Mandya' },
  { 'id': 5, 'name': 'Tumkur' },
];

export const CATEGORY: Category[] = [
  { 'id': 1, 'name': 'Category 1' },
  { 'id': 2, 'name': 'Category 2' },
  { 'id': 3, 'name': 'Category 3' },
  { 'id': 4, 'name': 'Category 4' }
]

export const WEEK: Week[] = [
  { 'id': 1, 'name': 'Week 1' },
  { 'id': 2, 'name': 'Week 2' },
  { 'id': 3, 'name': 'Week 3' },
  { 'id': 4, 'name': 'Week 4' },
  { 'id': 5, 'name': 'Week 5' },
]

export const POSITION: Position[] = [
  { 'id': 1, 'name': 'Week 1' },
  { 'id': 2, 'name': 'Week 2' },
  { 'id': 3, 'name': 'Week 3' },
  { 'id': 4, 'name': 'Week 4' },
  { 'id': 5, 'name': 'Week 5' },
]

export const RATING: RatingModel[] = [
  { 'id': 1, 'rating': 0, 'contact': 'Creativity' },
  { 'id': 2, 'rating': 0, 'contact': 'Sustainability' },
  { 'id': 3, 'rating': 0, 'contact': 'Usage of Colours' },
  { 'id': 4, 'rating': 0, 'contact': 'Photography' },
  { 'id': 5, 'rating': 0, 'contact': 'Nature Photography' },
  { 'id': 6, 'rating': 0, 'contact': 'Nature Photography' },
  { 'id': 7, 'rating': 0, 'contact': 'Nature Photography' }
]

// export const RATINGPARAMETER: RatingParameter[] = [
//   { 'id': 1, 'name': 'Creativity', 'rating': 0 },
//   { 'id': 2, 'name': 'Sustainability', 'rating': 0 },
//   { 'id': 3, 'name': 'Usage of Colours', 'rating': 0 },
//   { 'id': 4, 'name': 'Photography', 'rating': 0 },
//   { 'id': 5, 'name': 'Nature Photography', 'rating': 0 },
//   { 'id': 6, 'name': 'Nature of Phography', 'rating': 0 }
// ] 