export class Rewards {
    id: number;
    prod_name: string;
    status: number;
}

export class WeeksBasedEntriesData {
    week: string;
    total_rewards: string;
    confirmed_winners: string;
    pending_winners: string;
    overdue_rewards: string;
    reward_id: number;
    user_id: number;
}

export class rewardTabContent {
    title: string;
    content: WeeksBasedEntriesData[];
    active: boolean;
}

export class WeekBasedTask {
    title: string;
    task_from: string;
    value: number;
    related_status: number;
    user_id: number;
    isCurrentWeek: boolean;
    status: number;
    task_to: string;
    realted_task_name : string;
    from_status : number;
    from_status_name: string;
    task_from_count: number;
    id:number;
    subtask:number;
}

export class DashboardCountData {
    user_id: number;
    total_entries: number;
    shortlisted_entries: number;
    confirmed_entries: number;
    rejected_entries: number;
    onhold_entries: number;
    unopened_entries: number;
}


export class City {
    id: number;
    name: string;
}

export class Week {
    id: number;
    name: string;
}

export class Position {
    id: number;
    name: string;
}

export class Category {
    id: number;
    name: string;
}

export class ICompany {
    id: number;
    rating: number;
    contact: string;
    company: string;
}

export class DocumentsModel {
    id: number; 
    document_name : string;
    document_path : string;
    created_date :string;
    description: string;
    campaign_id : number;
}
