import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { EntriesRoutingModule } from './entries-routing.module';
import { UserDataProvider } from 'src/app/Shared/UserDataProvider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EntriesRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [UserDataProvider, DatePipe]
})
export class EntriesModule { }
