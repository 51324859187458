import { Injectable, Inject } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { UserService } from '../Service/user.service';
import { FCMTokenModel } from '../Models/login-form';


@Injectable()
export class MessagingService {

  private messaging: firebase.messaging.Messaging;
  currentMessage = new BehaviorSubject(null);
  public token = new BehaviorSubject('');

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private userService : UserService
    ) {
      this.angularFireMessaging.messaging.subscribe(
          (_messaging: any) => {
           _messaging.onMessage = _messaging.onMessage.bind(_messaging);
          _messaging._next = (payload: any) => {
              console.log(payload);
          };
          _messaging.onMessage((payload) => {
            this.messaging = payload.message();
            console.log(this.messaging);
         })
          _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
          }
        );


  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    let fcmModel: FCMTokenModel = new FCMTokenModel;
    fcmModel.user_id = userId;
    fcmModel.fcm_token = token;
    this.userService.UpdateFcmToken(fcmModel).subscribe(
      (data) => {
        if(data) {
          return;
        }
      }
    )
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.token.next(token)
        //this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging
    .messaging.subscribe(
      (message) => {
        message.onMessage((payload) => {
           this.messaging = payload.message();
        })
      })  
  }

  recevieMessageInApp() {
    this.angularFireMessaging.messages
    .subscribe((message) => { 
      console.log(message)
    });
  }


  listenToNotifications() {
    return this.angularFireMessaging.messages;
  }

  
}