import { Injectable } from '@angular/core';
import { UserProvider } from './UserProvider';
import { Rewards, WeeksBasedEntriesData, rewardTabContent, WeekBasedTask, DashboardCountData, DocumentsModel } from '../Models/rewards';
import { UserService } from '../Service/user.service';
import { Campaign, CampaignFaqContentModel } from '../Models/campaign';

@Injectable({
  providedIn: 'root'
})
export class DashboardProvider {

  rewards: Rewards[];
  rewardWeekBasedEntries: WeeksBasedEntriesData[];
  campaign: Campaign[];
  documents: DocumentsModel[];
  faqContent: CampaignFaqContentModel[];
  rewardEntries: rewardTabContent[] = [];
  campaignRewards: any;
  tasks: WeekBasedTask[] = [];
  dashboardCount: DashboardCountData[] = []
  constructor(public userService: UserService, private userProvider: UserProvider,
    private weekEntriesProvider: DashboardProvider) {

  }


  getCampaignRewards(): void {
    this.userService.getRewardsOfCampaign().subscribe(
      (response) => {
        this.rewardEntries = response.data;
      }
    )
  }

  getEntiresBasedTask(): void {
    this.userService.getTaskEntriesData().subscribe(
      (response) => {
        this.tasks = response.data;
        console.log(this.tasks);
      }
    )
  }

  getRewardsBasedData(): void {
    this.userService.getRewardsEntriesData().subscribe(
      (response) => {

        this.rewardWeekBasedEntries = response;
        
      }
    )
  }

  getDocuments(): void {
    this.userService.getRelatedDocs().subscribe(
      (response) => {
        this.documents = response.data;
      }
    )
  }

  getFaQs(): void {
    this.userService.getFaQData().subscribe(
      (response) => {
        this.faqContent = response.data;
      }
    )
  }

  getRewardTabContent(): void {
    this.rewardEntries = [];
    this.rewards.forEach(
      reward => {
        let loggedInUser = Number(localStorage.getItem('userId'));
        let tabsData: rewardTabContent = new rewardTabContent;
        tabsData.title = reward.prod_name;
        tabsData.content = this.rewardWeekBasedEntries.filter(w => w.reward_id == reward.id);
        (reward.id == 1) ? tabsData.active = true : tabsData.active = false;
        this.rewardEntries.push(tabsData);
      }
    )
  }

  getDashBoardCounts() {
    this.userService.getDashboardData().subscribe(
      (response) => {
        this.dashboardCount = response;
        let loggedInUser = Number(localStorage.getItem('userId'));
        if (this.dashboardCount.length) {
          this.dashboardCount = this.dashboardCount.filter(d => d.user_id == loggedInUser);
        }
      }
    )
  }

  checkDates(from, to) {
    let DateArray = [];
    let startDate = new Date(from);
    let endDate = new Date(to);

    while (startDate <= endDate) {
      DateArray.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1)
    }

    let today = new Date();
    return (DateArray.find(item => { return item == today }) || []).length > 0;
  }

  getCampaignData() {
    this.userService.getAllCampaigns().subscribe(
      (response) => {
        this.campaign = response.data.campaigns;
        this.campaign = this.campaign.filter(c => c.id == this.userService.CampaignId);
      }
    )
  }
}
